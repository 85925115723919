<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <!-- CARD 2: SUBSCRIBERS GAINED -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base" v-if="dashboardCount.tutorTotal">
        <statistics-card-line icon="UsersIcon" :statistic="dashboardCount.tutorTotal" :hideChart=true statisticTitle="Total Tutors" ></statistics-card-line>
      </div>

      <!-- CARD 3: ORDER RECIEVED -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base" v-if="dashboardCount.parentTotal">
        <statistics-card-line icon="UsersIcon" :statistic="dashboardCount.parentTotal" statisticTitle="Total Parents" :hideChart=true  type='area'></statistics-card-line>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base" v-if="dashboardCount.studentTotal">
        <statistics-card-line icon="UsersIcon" :statistic="dashboardCount.studentTotal" :hideChart=true statisticTitle="Total Students"  type='area'></statistics-card-line>
      </div>
    </div>
    <div class="content-area__heading">
    <h2 class="mb-5">Lesson</h2>
    </div>
    <div class="vx-row">
      <!-- CARD 2: SUBSCRIBERS GAINED -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 mb-base" v-if="dashboardCount.requested">
        <statistics-card-line icon="ShoppingBagIcon" :statistic="dashboardCount.requested" :hideChart=true color='warning' statisticTitle="Requested" ></statistics-card-line>
      </div>

      <!-- CARD 3: ORDER RECIEVED -->
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 mb-base" v-if="dashboardCount.accepted">
        <statistics-card-line icon="ShoppingBagIcon" :statistic="dashboardCount.accepted" statisticTitle="Accepted" :hideChart=true color='warning' type='area'></statistics-card-line>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 mb-base" v-if="dashboardCount.cancelled">
        <statistics-card-line icon="ShoppingBagIcon" :statistic="dashboardCount.cancelled" :hideChart=true statisticTitle="Cancelled" color='warning' type='area'></statistics-card-line>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 mb-base" v-if="dashboardCount.declined">
        <statistics-card-line icon="ShoppingBagIcon" :statistic="dashboardCount.declined" :hideChart=true statisticTitle="Declined" color='warning' type='area'></statistics-card-line>
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/6 mb-base" v-if="dashboardCount.completed">
        <statistics-card-line icon="ShoppingBagIcon" :statistic="dashboardCount.completed" :hideChart=true statisticTitle="Completed" color='warning' type='area'></statistics-card-line>
      </div>
    </div>

  </div>
</template>

<script>

  import VueApexCharts from 'vue-apexcharts'
  import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
  import analyticsData from './../ui-elements/card/analyticsData.js'
  import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
  import VxTimeline from "@/components/timeline/VxTimeline"
  import {mapActions} from "vuex";

  export default {
    data() {
      return {
        dashboardCount:{
          totalTutor: 0,
          totalParent: 0,
          totalStudent: 0,
          requested: 0,
          accepted: 0,
          cancelled: 0,
          declined: 0,
          completed: 0

        },
        checkpointReward: {},
        subscribersGained: {},
        ordersRecevied: {},
        salesBarSession: {},
        supportTracker: {},
        productsOrder: {},
        salesRadar: {},

        timelineData: [
          {
            color: "primary",
            icon: "PlusIcon",
            title: "Client Meeting",
            desc: "Bonbon macaroon jelly beans gummi bears jelly lollipop apple",
            time: "25 mins Ago"
          },
          {
            color: "warning",
            icon: "MailIcon",
            title: "Email Newsletter",
            desc: "Cupcake gummi bears soufflé caramels candy",
            time: "15 Days Ago"
          },
          {
            color: "danger",
            icon: "UsersIcon",
            title: "Plan Webinar",
            desc: "Candy ice cream cake. Halvah gummi bears",
            time: "20 days ago"
          },
          {
            color: "success",
            icon: "LayoutIcon",
            title: "Launch Website",
            desc: "Candy ice cream cake. Halvah gummi bears Cupcake gummi bears soufflé caramels candy.",
            time: "25 days ago"
          },
          {
            color: "primary",
            icon: "TvIcon",
            title: "Marketing",
            desc: "Candy ice cream cake. Halvah gummi bears Cupcake gummi bears.",
            time: "28 days ago"
          }
        ],
        analyticsData: analyticsData,
        dispatchedOrders: [],
      }
    },
    components: {
      VueApexCharts,
      StatisticsCardLine,
      ChangeTimeDurationDropdown,
      VxTimeline
    },
    methods:{
      ...mapActions('general', ['getDashboardData']),
      getDashboard(){
        let self = this;
        this.getDashboardData().then(async res => {
         console.log(res.data.data);

         self.dashboardCount=res.data.data;
         // self.totalParent=res.data.data.parentTotal;
         // self.totalTutor=res.data.data.tutorTotal;
        })
      }
    },
    created() {
      //  User Reward Card
      this.getDashboard();
      this.$http.get("/api/user/checkpoint-reward")
        .then((response) => { this.checkpointReward = response.data })
        .catch((error)   => { console.log(error) })

      // Subscribers gained - Statistics
      this.$http.get("/api/card/card-statistics/subscribers")
        .then((response) => { this.subscribersGained = response.data })
        .catch((error)   => { console.log(error) })

      // Orders - Statistics
      this.$http.get("/api/card/card-statistics/orders")
        .then((response) => { this.ordersRecevied = response.data })
        .catch((error)   => { console.log(error) })

      // Sales bar - Analytics
      this.$http.get("/api/card/card-analytics/sales/bar")
        .then((response) => { this.salesBarSession = response.data })
        .catch((error)   => { console.log(error) })

      // Support Tracker
      this.$http.get("/api/card/card-analytics/support-tracker")
        .then((response) => { this.supportTracker = response.data })
        .catch((error)   => { console.log(error) })

      // Products Order
      this.$http.get("/api/card/card-analytics/products-orders")
        .then((response) => { this.productsOrder = response.data })
        .catch((error)   => { console.log(error) })

      // Sales Radar
      this.$http.get("/api/card/card-analytics/sales/radar")
        .then((response) => { this.salesRadar = response.data })
        .catch((error)   => { console.log(error) })

      // Dispatched Orders
      this.$http.get("/api/table/dispatched-orders")
        .then((response) => { this.dispatchedOrders = response.data })
        .catch((error)   => { console.log(error) })
    }
  }
</script>

<style lang="scss">
  /*! rtl:begin:ignore */
  #dashboard-analytics {
    .greet-user{
      position: relative;

      .decore-left{
        position: absolute;
        left:0;
        top: 0;
      }
      .decore-right{
        position: absolute;
        right:0;
        top: 0;
      }
    }

    @media(max-width: 576px) {
      .decore-left, .decore-right{
        width: 140px;
      }
    }
  }
  /*! rtl:end:ignore */
</style>
